<template>
  <div class="stickers">
    <input type="text" class="search" v-model="searchTerm" placeholder="Search...">

    <div class="btn-add" @click="openStickerForm()"> + </div>

    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <draggable :list="stickers" handle=".sticker__title" class="stickers-grid" group="stickers" @change="onDragSticker($event, stickers)">
      <div class="stickers-grid__item" v-for="(sticker) in stickers" :key="sticker.id">
        <Sticker :sticker="sticker" class="-draggable"/>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Sticker from './Sticker'
import StickerForm from '@/modules/Stikers/StickerForm'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'
import Loading from 'vue-loading-overlay'
import DefaultSticker from './Model/Sticker'

export default {
  name: 'Stickers',
  components: {
    Sticker,
    Loading,
    draggable
  },
  mounted () {
    this.loadAll()
  },
  computed: {
    ...mapGetters('Stickers', ['search', 'isLoading']),
    searchTerm: {
      get () {
        return this.search
      },
      set (value) {
        this.$store.commit('Stickers/setSearch', value)
      }
    },
    stickers: {
      get() {
        return this.$store.getters['Stickers/stickers']
      },
      set() {}
    }
  },
  methods: {
    ...mapActions('Stickers', ['loadAll', 'add', 'edit', 'remove', 'reorder']),
    addSticker(sticker) {
      sticker.id = uuidv4()
      sticker.orderSeq = this.stickers.length

      return this.add(sticker)
    },
    onDragSticker (e, stickers) {
      if (typeof e.moved !== 'undefined') {
        this.reorder(stickers)
      }
    },
    openStickerForm () {
      const sticker = { ...DefaultSticker }

      this.$modal.show(
          StickerForm,
          {
            sticker: sticker,
            saveSticker: this.addSticker
          },
          {
            adaptive: true,
            width: '318px',
            height: 'auto'
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.stickers {
  max-width: 1250px;
  margin: 0 auto;
}

.search {
  display: block;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  color: #fff;
  padding: 10px 0;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  width: 100%;
  border-radius: 2px;

  &::placeholder {
    color: #ddd;
  }
}

.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  height: 40px;
  width: 60px;
  font-size: 50px;
  border-radius: 20px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.3);
  user-select: none;
  color: #222;
  background: rgb(130, 207, 241);
  background: linear-gradient(to bottom, #82cff1 0%, #38aeea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#82cff1', endColorstr='#38aeea', GradientType=0);

  &:hover {
    background: linear-gradient(to top, rgba(130, 207, 241, 1) 0%, rgba(56, 174, 234, 1) 100%);
    cursor: pointer;
  }
}

.stickers-grid {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  &__item {
    padding: 0 8px;
    margin-bottom: 20px;
    width: 316px;
  }
}

</style>
